<template>
  <Modal
    :activeModal="isActiveModal"
    @closeModal="closeModal"
    :widthModal="550"
  >

    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          Confirmar pagamento
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-form>
        <div class="abas" width="100%">
          <v-tabs
            v-model="tab"
            background-color="#FFF"
            height="30px">
            <v-tab href="#tab-1" class="abas-item">
              Confirmar Pagamento
            </v-tab>

            <v-tab href="#tab-2" class="abas-item">
              Comprovantes
            </v-tab>
          </v-tabs>
        </div>

        <v-col cols="12">
          <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="pa-3 pt-0 pb-0">
                      <strong>Revenda:</strong> 
                    </v-col>

                    <v-col cols="8" class="text-right pa-3 pt-0 pb-0">
                      {{ codEntity }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="pa-3 pt-0 pb-0">
                      <strong>PIX:</strong> 
                    </v-col>

                    <v-col cols="8" class="text-right pa-3 pt-0 pb-0">
                      {{ chavePixRevenda }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="pa-3 pt-0 pb-0">
                      <strong>Contato:</strong> 
                    </v-col>

                    <v-col cols="8" class="text-right pa-3 pt-0 pb-0">
                      <a :href="'https://wa.me/' + contatoRevenda" target="_blank"> {{ contatoRevenda | phone2 }} </a>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pa-3 pt-0 pb-0">
                      <strong>Valor:</strong> 
                    </v-col>

                    <v-col class="text-right pa-3 pt-0 pb-0">
                      {{ valorTransacao | currency }}
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6">
                  <v-row>
                    <v-col cols="4" class="pa-3 pt-0 pb-0">
                      <strong>Usuário:</strong> 
                    </v-col>

                    <v-col cols="8" class="text-right pa-3 pt-0 pb-0">
                      {{ nomeUsuarioWeb }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="pa-3 pt-0 pb-0">
                      <strong>PIX:</strong> 
                    </v-col>

                    <v-col cols="8" class="text-right pa-3 pt-0 pb-0">
                      {{ chavePixUsuarioWeb }}
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4" class="pa-3 pt-0 pb-0">
                      <strong>Contato:</strong> 
                    </v-col>

                    <v-col cols="8" class="text-right pa-3 pt-0 pb-0">
                      <a :href="'https://wa.me/' + codEntityWeb" target="_blank"> {{ codEntityWeb | phone2 }} </a>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="6" class="pa-3 pt-0 pb-0">
                      <strong>Transação:</strong> 
                    </v-col>

                    <v-col cols="6" class="text-right pa-3 pt-0 pb-0">
                      {{ transactionId }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>


      <v-tabs-items v-model="tab" touchless>
        <v-tab-item
          :key="1"
          :value="'tab-1'"
        >
          <v-card-text class="modal-cadastro-form" :key='componentKey1+"A"'>
            <v-row  v-for="(it, key, index) in dataImages.jsonData.images" :key="index" class="mb-2">
              <v-col cols="11" class="modal-cadastro-form-col-imput">
                <v-text-field
                  v-model="it.description"
                  outlined
                  clearable
                  label="Descrição"
                  type="text"
                  dense
                  :hint="it.name"
                  persistent-hint
                  :disabled="it.isRegister"
                >
                  <template v-slot:append>
                    <v-file-input
                      label="File input"
                      filled
                      outlined
                      dense
                      hide-input
                      prepend-icon="mdi-camera"
                      class="mt-n2"
                      @change="addImage(it, $event)"
                    />
                  </template>
                </v-text-field>
              </v-col>

              <v-col dense cols="1" class="modal-cadastro-form-col-imput pl-0">
                <v-btn 
                  v-if="isPlus(index)"
                  width="25"
                  height="25"
                  fab
                  dark
                  small
                  :color="variables.colorPrimary"
                  class="fab-remove-item mt-1"
                  @click="addItem(key)"
                >
                  <v-icon dark small>mdi-plus</v-icon>
                </v-btn>

                <v-btn 
                  v-else
                  width="25"
                  height="25"
                  fab
                  :dark="!it.isRegister"
                  small
                  color="red"
                  class="fab-remove-item mt-1"
                  @click="removeItem(key)"
                  :disabled="it.isRegister"
                >
                  <v-icon dark small>mdi-minus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-sheet
            class="mx-auto pl-0 pr-0"
            elevation="0"
            max-width="450"
          >
            <v-slide-group
              v-model="modelSlide"
              class="pl-0 pr-0 pb-4"
              center-active
              show-arrows
            >
              <template v-for="(it, key, index) in dataImages.jsonData.images">
                <v-slide-item
                  :key="index"
                  v-slot="{ active, toggle }"
                  v-if="it.name"
                  
                >
                  <v-card
                    class="white--text align-end ma-1"
                    :color="active ? 'primary' : 'grey lighten-1'"
                    height="150"
                    width="150"
                  >
                    <v-btn
                      :color="active ? 'secondary' : 'primary'"
                      @click="openModalImagem({ description: it.description, image: formatarUrlImagem(it.image), type: it.type })"
                      small
                      class="btn-select"
                    >
                      Ver
                    </v-btn>

                    <v-img
                      height="150"
                      width="150"
                      :src="formatarUrlImagem(it.image)" aspect-ratio="1"
                      v-if="it.type !== 'application/pdf'"
                    />

                    <iframe v-else :src="formatarUrlImagem(it.image)" width="100%" height="300px" style="border: none;"/>
                  </v-card>
                </v-slide-item>
              </template>
            </v-slide-group>
          </v-sheet>
        </v-tab-item>

        <v-tab-item
          :key="2"
          :value="'tab-2'">

          <v-col class="d-flex justify-center align-center" style="min-height: 150px; width: 100%;" v-if="loadingRequestImages">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </v-col>

          <v-sheet
            class="mx-auto pl-0 pr-0"
            elevation="0"
            max-width="450"
            v-else
          >
            <v-slide-group
              v-model="modelSlideImages"
              class="pl-0 pr-0 pb-4"
              show-arrows
              multiple
            >
              <v-slide-item
                v-for="image in images"
                :key="image.id"
                v-slot="{ active, toggle }"
              >
                <v-card
                  class="white--text align-end ma-1"
                  height="150"
                  width="150"
                >
                  <v-btn
                    :color="active ? 'secondary' : 'primary'"
                    @click.stop="toggle"
                    small
                    class="btn-select"
                  >
                    Selecionar
                  </v-btn>
                  <v-img
                    height="150"
                    width="150"
                    :src="config.urlAWS + '/' + image.image" aspect-ratio="1"
                    v-if="image.type !== 'application/pdf'"
                  />

                  <iframe v-else :src="config.urlAWS + '/' + image.image" width="100%" height="300px" style="border: none;"/>
                  
                  <v-btn
                    :color="'secondary'"
                    small
                    class="btn-ver"
                    @click="openModalImagem({ image: `${config.urlAWS}/${image.image}` })"
                  >
                    Ver
                  </v-btn>

                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>


        <v-card-actions class="modal-cadastro-footer">
          <!-- <div></div> -->
          <v-btn 
            :color="variables.colorError" 
            :loading="loadingBtnSorteio" 
            @click.prevent="solicitarOutraRevenda" 
            dark
          >
            Atribuir outra revenda
          </v-btn>

          <div class="d-flex">
            <v-select
              v-model="statusTransaction"
              :items="transacaoStatus"
              label="Status Pix"
              dense
              hide-details
              solo
              class="select-status mr-2"
              :error-messages="errorStatusTransaction"
              :width="150"
            />

            <v-btn 
              :color="variables.colorPrimary" 
              :loading="loadingBtn" 
              @click.prevent="salvarDados"
              dark
            >Alterar status</v-btn>
          </div>

        </v-card-actions>
      </v-form>
    </v-card>

    <ModalFoto
      :isActiveModal="isOpenModalFoto"
      :data="dataAnexo"
      @closeModal="closeModalFoto"
    />

    <ModalReasonCancel
      :isActiveModal="isOpenModalReasonCancel"
      :reasonCancelProps.sync="dataImages.jsonData.reasonCancel"
      @closeModal="closeModalReasonCancel"
      @confirmarCancelamentooo="confirmarCancelamento()"
    />
  </Modal>
</template>

<script>
// eslint-disable-next-line
import { mapActions, mapGetters } from 'vuex'
// import Events from '@/core/service/events'
// eslint-disable-next-line
import { required, requiredIf } from 'vuelidate/lib/validators'
import variables from '@/assets/styles/helpers/_variables.scss'
// eslint-disable-next-line
import { filter, size, cloneDeep, each, flatMap } from 'lodash'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar } from '@/core/service/utils'
import config from '@/core/config'
import moment from 'moment'

export default {
  name: 'ModalConfirmationPaymentPix',
  props: {
    isActiveModal: {
      type: Boolean,
      default: false
    },
    transactionProp: {
      type: Object,
      default: (() => {})
    },
    entityWeb: {
      type: Object,
      default: (() => {})
    }
  },
  components:{
    Modal: () => import('@/views/components/modal'),
    ModalFoto: () => import('./modalFoto'),
    ModalReasonCancel: () => import('./modalReasonCancel')
  },
  data: () => ({
    modelSlide: null,
    modelSlideImages: [],
    loading: false,
    menuDateInicial: false,
    dtInicial: '',
    activeModal: false,
    loadingBtn: false,
    loadingRequestImages: false,
    loadingBtnSorteio: false,
    isOpenModalFoto: false,
    isOpenModalReasonCancel: false,
    qtdItemForm: 1,
    dataAnexo: {},
    statusTransaction: '',
    tab: 'tab-1',
    dataImages: {
      jsonData: {
        images: {
          item1: {
            image: '',
            description: '',
            name: ''
          }
        }
      }
    },
    componentKey1: 0
  }),
  computed: {
    ...mapGetters('roles', ['permiteAcao']),
    ...mapGetters('despacho', ['images', 'transacaoStatus']),
    variables: () => variables,
    transaction: {
      get: function () {
        return this.transactionProp
      },
      
      set: function (newValue) {
        console.log('newValue', newValue)
      }
    },

    config: () => config,

    codEntity () {
      return size(this.transaction) ? this.transaction.entity.cod : ''
    },
    
    valorTransacao () {
      return size(this.transaction) ? this.transaction.value : 0
    },

    chavePixRevenda () {
      return size(this.transaction) ? this.transaction.entity.jsonData.pix.keyCode.key : 0
    },

    contatoRevenda () {
      return size(this.transaction) ? this.transaction.entity.jsonData.phone : 0
    },

    nomeUsuarioWeb () {
      return size(this.entityWeb) ? this.entityWeb.jsonData.fullName : ''
    },

    chavePixUsuarioWeb () {
      return size(this.entityWeb) ? this.entityWeb.jsonData.pix.keyCode : ''
    },

    codEntityWeb () {
      return size(this.entityWeb) ? this.entityWeb.cod : ''
    },

    transactionId () {
      return size(this.transaction) ? this.transaction.id : ''
    },

    stringTypeBtn () {
      if (!size(this.transaction))  return ''
      // return ['B', 'C'].includes(this.transaction.transactionStatusId) ? 'Cancelar pagamento' : 'Confirmar pagamento'
      switch (this.transaction.transactionStatusId) {
        case 'B':
        case 'C':
          return 'Cancelar pagamento'
        case 'AC':
          return 'Cadastrar comprovante'
        default:
          return 'Confirmar pagamento'
      }
    },

    disabledBtn () {
      if (!size(this.transaction))  return ''
      return ['E', 'R', 'A'].includes(this.transaction.transactionStatusId)
    },

    disabledBtnSorteio () {
      if (!size(this.transaction))  return ''
      return !['AC', 'A'].includes(this.transaction.transactionStatusId)
    },

    errorStatusTransaction () {
      const errors = []
      if (!this.$v.statusTransaction.$dirty) return errors
      !this.$v.statusTransaction.required && errors.push('Status é obrigatório')
      return errors
    },
  },
  mounted () {
  },
  watch: {
    transaction: function () {
      this.dataImages = cloneDeep(this.transactionProp)
      this.statusTransaction = this.transactionProp.transactionStatusId

      if (this.dataImages.jsonData && this.dataImages.jsonData.images) {
        const images = {}
        each(this.dataImages.jsonData.images, (it, idx) => {
          images[`item${idx+1}`] = {
            image: it.image,
            description: it.description,
            name: it.name,
            type: it.type,  
            isRegister: true 
          }
        })

        this.qtdItemForm =  this.dataImages.jsonData.images.length
        this.dataImages.jsonData.images = images
        return this.dataImages
      }

      if (this.dataImages.jsonData) {
        this.dataImages.jsonData.images = {
          item1: {
            image: '',
            description: '',
            name: ''
          }
        }
      } else {
        this.dataImages.jsonData = {
          images: {
            item1: {
              image: '',
              description: '',
              name: ''
            }
          }
        }
      }
    },

    tab: function (val) {
      console.log(val)
      if (val === 'tab-2') {
        const date = moment(this.transaction.created).format('YYYY-MM-DD')

        const entityId = this.transaction.transactionTypeId === 'C' ? this.entityWeb.id : this.transaction.entityId

        this.loadingRequestImages = true
        this.getReceiptsEntity({ entityId, date })
          .finally(() => this.loadingRequestImages = false)
      }
    },

    modelSlideImages: function(val) {
      this.setImage(val)
    }
  },
  methods: {
    ...mapActions('despacho', ['confirmationPayment', 'getReceiptsEntity', 'cancelPayment', 'selecionarOutraRevenda', 'acceptTransaction', 'clearImages', 'saleCredits']),

    closeModal () {
      this.$emit('closeModal', false)
      this.tab = 'tab-1'
      this.clearImages()
    },
    clear () {

    },

    removeItem(item) {
      if (size(this.dataImages.jsonData.images) === 1) {
        return
      }
      this.$delete(this.dataImages.jsonData.images, item)
      this.componentKey1++
    },

    addItem () {
      if (size(this.dataImages.jsonData.images) > 9) {
        return
      }
      this.qtdItemForm++
      this.$set(this.dataImages.jsonData.images, `item${this.qtdItemForm}`, {
        description: '',
        image: '',
        name: ''
      })
      this.componentKey1++
    },

    addImage (item, e) {
      if (!e) {
        item.image = ''
        return
      }
      if (!e.type.match('image.*')  && !e.type.match('application/pdf')) {
        item.image = ''
        return
      }
      // const img = new Image(),
      item.name = e.name
      item.type = e.type
      const reader = new FileReader()
      reader.onload = (e) => item.image = e.target.result
      reader.readAsDataURL(e)
      if (this.transactionProp.transactionStatusId === 'AC') {
        this.statusTransaction = 'PG'
      }
      setTimeout(() => this.componentKey1++, 100)
    },

    isPlus(index) {
      return !index
    },

    salvarDados () {
      if (this.$v.statusTransaction.$invalid || this.statusTransaction === this.transactionProp.transactionStatusId) {
        this.$v.statusTransaction.$touch()
        return 
      }

      if (this.statusTransaction === 'B' && this.transactionProp.type.id === 'C') {
        this.baixarCredito()
        return
      }

      if (this.statusTransaction === 'R') {
        this.solicitarOutraRevenda
      } else if (['CA'].includes(this.statusTransaction) || this.transactionProp.transactionStatusId === 'B') {
        this.isOpenModalReasonCancel = true
      } else if (this.statusTransaction === 'AC') {
        this.aceitarTransacao()
      } else {
        this.confirmPayment(this.statusTransaction !== 'B')
      }
    },

    confirmPayment(envioComprovante = false) {
      if (['B', 'PG'].includes(this.statusTransaction) && this.$v.dataImages.jsonData.images.$invalid) {
        this.$v.dataImages.jsonData.images.$touch()
        return 
      }

      if (!this.dataImages.id) {
        this.$swal({
          icon: 'error',
          text: `ID da transação inválido`,
          showCancelButton: false,
          showConfirmButton: false
        })

        return false
      }

      this.loadingBtn = true
      this.confirmationPayment({ 
        transactionId: this.dataImages.id, 
        images: Object.values(this.dataImages.jsonData.images), 
        envioComprovante, 
        statusTransaction: this.statusTransaction 
      })
      .then(() => {
        const msg = envioComprovante ? 'Comprovante registrado com sucesso' : 'Pagamento registrado com sucesso!'
        successSnackbar(msg)
        this.$emit('registroConfirmado', true)
      })
      .catch(err => errorSnackbar(err.error))
      .finally(() => this.loadingBtn = false)
    },

    confirmarCancelamento() {
      if (this.$v.dataImages.jsonData.reasonCancel.$invalid) {
        this.$v.dataImages.jsonData.reasonCancel.$touch()
        return 
      }
      this.closeModalReasonCancel()
      this.loadingBtn = true
      this.cancelPayment({ 
          transactionId: this.dataImages.id, 
          images: Object.values(this.dataImages.jsonData.images), 
          reasonCancel: this.dataImages.jsonData.reasonCancel,
          statusTransaction: this.statusTransaction
        })
        .then(() => {
          successSnackbar('Cancelamento registrado com sucesso!')
          this.$emit('registroConfirmado', true)
        })
        .catch(err => errorSnackbar(err.error))
        .finally(() => this.loadingBtn = false)
    },

    solicitarOutraRevenda () {
      this.loadingBtnSorteio = true
      this.selecionarOutraRevenda({ transactionId: this.transaction.id })
        .then(() => {
          successSnackbar('Cancelamento registrado com sucesso!')
          this.$emit('registroConfirmado', true)
        })
        .catch((err) => errorSnackbar(err.error))
        .finally(() => this.loadingBtnSorteio = false)
        
    },

    aceitarTransacao () {
      this.loadingBtnSorteio = true
      this.acceptTransaction({ transactionId: this.transaction.id })
        .then(() => {
          successSnackbar('Transação aceita!')
          this.$emit('registroConfirmado', true)
        })
        .catch((err) => errorSnackbar(err.error))
        .finally(() => this.loadingBtnSorteio = false)
        
    },

    openModalReasonCancel() {

    },

    baixarCredito() {
      this.loadingBtn = true
      this.saleCredits({ 
        transactionId: this.dataImages.id, 
        images: Object.values(this.dataImages.jsonData.images),
        statusTransaction: this.statusTransaction
      })
      .then(() => {
        successSnackbar('Crédito baixado com sucesso!')
        this.$emit('registroConfirmado', true)
      })
      .catch(err => errorSnackbar(err.error))
      .finally(() => this.loadingBtn = false)
    },

    setImage (positionArrayImage) {
      // const images = {}
      positionArrayImage.forEach(it => {
        const image = this.images[it]

        // imagens ja cadastradas ou selecionadas
        const images = filter(this.dataImages.jsonData.images, it => it.image)
        const index = size(images) + 1
        this.dataImages.jsonData.images[`item${index}`] = {
          image: image.image,
          description: '',
          name: `comprovante-${String(index + 1).padStart(2, '0')}`,
          isNew: true,
          idImage: image.id
        }
      })
      // const imagesRegistrada = filter(this.dataImages.jsonData.images, it => it.isRegister)
      // this.dataImages.jsonData.images.unshift(imagesRegistrada)
      this.qtdItemForm =  this.dataImages.jsonData.images.length
      // if (size(this.dataImages.jsonData.images)) {}
    },

    openModalImagem (image) {
      this.dataAnexo = image
      this.isOpenModalFoto = true
      console.log(image)
    },

    closeModalFoto () {
      this.dataAnexo = {}
      this.isOpenModalFoto = false
    },

    closeModalReasonCancel () {
      this.isOpenModalReasonCancel = false
    },

    errorMensagens (index) {
      let error = []
      if (!this.$v.dataImages.jsonData.images[index]) return []
      if (!this.$v.dataImages.jsonData.images[index].$dirty) return []

      !this.$v.dataImages.jsonData.images[index]['description'].required && error.push('Descrição é obrigatório')
      !this.$v.dataImages.jsonData.images[index]['image'].required && error.push('Anexo é obrigatório')
      console.log(error)
      return error
    },

    formatarUrlImagem (imagem) {
      if (String(imagem).indexOf(';base64') !== -1) return imagem

      return `${config.urlAWS}/${imagem}`
    }
  },

  validations () {
    let items = {}
    let obj = {}

    if (this.transaction && this.transaction.transactionStatusId !== 'B') {
      obj = flatMap(this.dataImages.jsonData.images, (v, k) => {
        return { 
          [k]: {
            image: { required },
            // description: { required },
          }
        }
      })
      obj.forEach(v => {
        items[Object.keys(v)[0]] = v[Object.keys(v)[0]]
      })
    }

    return { 
      dataImages: {
        jsonData: {
          images: items,
          reasonCancel: { required:  requiredIf(() => this.transaction && this.transaction.transactionStatusId === 'B') }
        }
      },

      statusTransaction: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/components/modal-cadastro';
.modal-cadastro-form-col-input .auto {
  background: red;
}
.theme--light.v-input input, .theme--light.v-input textarea{
  padding: 2;
}
.theme--light.v-input{
  padding: 0;
  margin: 0;
}
#bug{
  margin-top: 7px !important
}

.v-image {
  position: relative;
}

.btn-select {
  position: absolute;
  z-index: 10;
  top: 5px;
  right: 5px;
}

.btn-ver {
  position: absolute;
  z-index: 10;
  bottom: 5px;
  right: 5px;
}

.select-status::v-deep {
  .v-input__slot {
    width: 150px;
    margin-bottom: 0 !important;
  }
}
</style>
